import React from 'react';
import styled from 'styled-components';
import * as Mixins from '../Mixins';
import * as t from '../Typography';
import Layout, { Content } from '../components/Layout';
import HireMePopup from '../components/HireMePopup.js';
import { media } from '../MediaQueries';
import Img from 'gatsby-image';
import { graphql } from 'gatsby';
import logo from '../images/logo.png';

const AboveFold = styled.div`
  ${Mixins.aboveFoldMixin}
  padding-bottom: 100px;

  ${t.H1} {
    margin-bottom: 25px;
  }
  ${t.H4} {
    line-height: 1.14;
  }
  ${media.tablet`background-position: center top 0px;`};
`;

const AboutMeWrapper = styled.div`
  ${Mixins.wrapper}
  .m-b-60 {
    margin-bottom: 60px;
  }
  ${t.LargeP} {
    margin-bottom: 28px;
  }
  .background {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: -1;
    min-height: 540px;
    ${media.phone`min-height: 620px;`};
  }
  .gatsby-image {
    ${media.tablet`text-align: center;`}
    div {
      padding: 0;
    }
    picture img {
      max-width: 85%;
      position: relative;
      ${media.tablet`max-width: 80%;`}
    }
  }
  .avatar {
    max-width: 400px;
    width: 80%;
    margin: 0 auto 100px auto;
    display: block;
    ${media.tablet`max-width: 70%;`}
  }
`;

class AboutMe extends React.Component {
  state = {
    openHireMePopup: false
  };

  handleRequestDemoClose = () => {
    this.setState({
      openHireMePopup: false
    });
  };

  openContactPopup = () => {
    this.setState({
      openHireMePopup: true
    });
  };

  render() {
    const { openHireMePopup } = this.state;
    const { data } = this.props;
    return (
      <AboutMeWrapper>
        <Layout theme="white" openContactPopup={this.openContactPopup}>
          <AboveFold>
            <t.H1 green align="center">
              About
            </t.H1>
            <t.LargeP align="center" max70>
              remco is a full-service web development firm that takes pleasure in creating sturdy, contemporary, and
              mobile-friendly digital spaces. Collaboration is important to us at remco at every stage of the process,
              from analysing your needs and aspirations through user approval and our post-project support, which leaves
              you with your own unique webspace.{' '}
            </t.LargeP>

            <t.LargeP align="center" max70>
              our aim for each webspace is to be to work with you to create a modern, eye-catching space which is right
              for you. we give a high-quality service from start to end and will keep in touch with you frequently so
              you know where we are and may modify it at any moment. remco was established to instigate change, to alter
              your platform, to change consumer perceptions, and to change the archaic method that traditional digital
              agencies deal with you, remco, change with us.
            </t.LargeP>
            <img style={{ height: undefined, width: 300, flex: 1 }} resizeMode="contain" src={logo} alt="Remco Web" />
          </AboveFold>
          <Content></Content>
        </Layout>
        <HireMePopup open={openHireMePopup} handleClose={this.handleRequestDemoClose} />
      </AboutMeWrapper>
    );
  }
}

export default AboutMe;

export const pageQuery = graphql`
  query {
    avatarAbout: file(relativePath: { eq: "logo.png" }) {
      ...fluidImage
    }
  }
`;
